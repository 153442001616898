import React, { useState } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <header className="header-top ">
      <nav className="navbar">
        <div class="container">
          <div className="logo">
            <Link to={"/"}>
              <img
                src={
                  process.env.PUBLIC_URL + "./assets/images/content/logo.png"
                }
                alt="LOGO"
              ></img>
            </Link>
          </div>

          <button className="hamburger" onClick={() => setShowMenu(!showMenu)}>
            <FiMenu className="fi-menu" />
          </button>
          <div className={showMenu ? "menu show" : "menu"}>
            <ul>
              <Link
                to={"/"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>Home</li>
              </Link>
              <Link
                to={"/whoweare"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>Who We Are</li>
              </Link>
              <Link
                to={"/whatwedo"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>What We Do</li>
              </Link>
              <Link
                to={"/principles"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>Principles</li>
              </Link>
              <Link
                to={"/policies"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>Policies</li>
              </Link>
              <Link
                to={"/joinus"}
                onClick={() => setShowMenu(false) & window.scrollTo(0, 0)}
              >
                <li>Join Us</li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
