import React, { useState, useEffect } from "react";
import "./popup.scss";
import { IoIosArrowUp } from "react-icons/io";
function Popup() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleClick() {
    window.scrollTo(0, 0);
  }

  return (
    <button
      className={showButton ? "show-button" : "hide-button"}
      onClick={handleClick}
    >
      <IoIosArrowUp className="arrow-up" />
    </button>
  );
}

export default Popup;
