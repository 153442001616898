import React from "react";
import "./about.scss";
import TopCard from "../../components/TopCard/TopCard";
import { Helmet } from "react-helmet";


const partners = [
  {
    partner:'Women councils'
  },
  {
    partner:'Local youth organizations'
  },
  {
    partner:'Community leaders'
  },
  {
    partner:'Local and national institutions'
  },
  {
    partner:'Civil society groups'
  },
  {
    partner:'Gender diverse individuals'
  },
  {
    partner:'Media'
  },
  {
    partner:'Private sector'
  },
  {
    partner:'Indigenous communities'
  }
]


export default function About() {





  return (
    <div className="container">
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="keywords"
          content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
        />
        <link rel="canonical" href="https://climate-displacementaction.org" />
        <meta name="author" content="Fayez Sahak" />
        <meta
          property="og:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://climate-displacementaction.org"
        />
        <meta
          property="og:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          property="og:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta
          name="twitter:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta
          name="twitter:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="twitter:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@climatedisplacementaction" />
      </Helmet>

      <TopCard
        cardTitle={"What We Do"}
        cardImage={process.env.PUBLIC_URL + "/assets/images/svgs/contact.svg"}
      />

      <secton className="about-section">
        <div className="about">
          <div className="image ">
            <img
              src={process.env.PUBLIC_URL + "./assets/images/content/img-6.jpg"}
              alt="no found"
            ></img>
          </div>
          <div className="col-lg-5 txt">
            <p style={{textAlign:'justify',}}>
              <span>CLIMATE-DISPLACEMENT ACTION </span>assesses the operational and institutional capacity of local organizations in provinces where it operates. We support them through capacity building and resource mobilization so they enjoy an enabling environment. 
CDA also focuses to increase the influence of local partners by facilitating the creation of joint strategies, action plans, seminars, and workshops between partners and by publicizing their analyses and recommendations to ensure informed decision-making.

            </p>
          </div>
        </div>
        <div className="mission">
        <h3 className="section-title">Vision</h3>
          <p>
            CLIMATE-DISPLACEMENT ACTION is striving for a world in which people
            live in harmony with nature and experience (re)-integration, social
            cohesion, and peaceful co-existence.
          </p>


          <h3 className="section-title">Mission</h3>
          <p>
            CLIMATE-DISPLACEMENT ACTION protects and serves local communities
            impacted as a result of climate change, including internally
            displaced persons, migrants, refugees, and host communities
            worldwide. We support climate-affected communities through
            livelihood, integration support, information-sharing, and adaptation
            to climate change.
          </p>
          <h3 className="section-title">How We Do It</h3>

          <p>
          We collaborate with our provincial and district-level partners and share best practices, and the lessons we all have learned to achieve our mission more successfully and more effectively. Our local partners know the needs and challenges that exist in their communities and have contextualized and clear vision, which is central for designing and developing ideas for promising future actions. While information-sharing is critical to ensure participation, we are committed to equal partnership. 
          </p>
          <p>
          CLIMATE-DISPLACEMENT ACTION assesses the operational and institutional capacity of local organizations in provinces where it operates. We support them through capacity building and resource mobilization so they enjoy an enabling environment. 
CDA also focuses to increase the influence of local partners by facilitating the creation of joint strategies, action plans, seminars, and workshops between partners and by publicizing their analyses and recommendations to ensure informed decision-making.

          </p>
          <p>
          CDA identifies other areas where social re-integration and social cohesion are a challenge but might have a cultural/sports infrastructure or organization active. As per the result of need analyses, we reach out to relevant sources to create cultural and social platforms between IDPs, returnees, and host community members. 
          </p>
          <p>
          CDA seeks funding to build sports infrastructure or develop if there is any existing sports infrastructure to foster youth engagement in positive activities. This is particularly important because establishing safe spaces for community members, particularly women and girls will encourage them to speak and in the long run, increase understanding and reduce social tensions. 
          </p>
            <p>
            CDA works with Olympic committees/ youth clubs to arrange sports events and enhance understanding and peaceful co-existence among IDPs, returnees, and potential migrants, and host communities.
            </p>
            <p>
            CDA works with grassroots youth organizations and civil society groups and movements to promote and support a wide-scale inclusion of IDPs, returnees, potential migrants, and host communities through community cultural and social awareness programs. 
            </p>

            <h3 className="section-title">Early Warning, Early Action</h3>
            <p>
            CDA has identified a lack of awareness regarding the gradual onset of drought in drought-prone Afghan communities. This slow onset weakens the resilience of households due to limited coping mechanisms, disrupting livelihoods and pushing them into severe food insecurity. Early actions to mitigate the impact of drought conditions are hindered because early warnings are not communicated widely enough, and their analysis and understanding are insufficient.
            </p>
            <p>
            Scientific evidence suggests that the impact of a dry spell at the community level should be rigorously monitored through early warning signs.
            </p>
            <p>
            To address this gap, CDA is focused on enhancing the dissemination and analysis of early warning signs. We collaborate with national disaster management agencies and local community partners to translate projections from the Famine Early Warning Systems Network (FEWS NET) and other reports on humanitarian crises into local languages and issue early drought notifications. We also advocate for the release of early financing for interventions aimed at building resilience and providing safety nets.
            </p>

          
          
          
         
          <h3 className="section-title">
            Partnership
          </h3>
          <p>
          Development is a holistic and participatory process because we cannot bring about change in communities alone. We, therefore, prioritize working in partnership with.
          </p>
          
        <ul className="partners">
          {partners.map((item)=>(
        
            <li>
            <span className="para" style={{fontSize:'18px'}}>{item.partner}</span>
            </li>
            
            ))}
         
        </ul>

        <h3 className="section-title">Research & Advocacy</h3>
        <p>
        At CDA, we travel to different and hard-to-access regions of Afghanistan, listen, and speak to those affected by climate change. Through our in-country monitoring and research activities, we first identify the problem and understand how best we can generate change. We share our findings with stakeholders at all levels and engage the public and policymakers for meaningful action to help those in need.
        </p>
        <p >
        As part of our advocacy efforts, 
        </p>
        <p>
        We conduct research, and bring together experts, government officials, and community representatives to share knowledge and find solutions to the climate crisis, focusing on specific contexts.
        </p>
        <p>
        Engage key stakeholders in government and communities to dedicate increased funds for community-based education, diversified livelihood opportunities, and child protection and preventing gender-based violence. Aimed at increasing the protection of at-risk groups from the effects of conflict, advocacy efforts are specifically designed to prevent and respond to rights violations.
        </p>
        <p>
        Launch awareness campaigns on protection risks associated with irregular migrations, and how human smuggling transforms into human trafficking.
        </p>
        <p>
        Lead awareness campaigns on Gender-Based Violence in communities, and 5; raise our voices to inform, influence, and advance local efforts to ensure all people have access to food and remain safe from protection risks that are caused by food insecurity.
        </p>


        <h3 className="section-title">Livelihood</h3>
          <p>
          The socio-economic profiling of vulnerable communities where CDA works clearly identified an increased need for employment.  CDA addresses pressing employment needs in vulnerable communities by offering skills training, bridging gaps between existing skills and market demands. 
          </p>

          <p>
          We focus on helping vulnerable communities and individuals learn new skills and seize opportunities through Technical Vocational Education Training (TVET), on-the-job training, and job-searching programs. Our approach ensures inclusivity, preventing tensions by involving both IDPs and the host community in training. We empower beneficiaries to launch small businesses with necessary resources, closely monitoring and enhancing training quality. 
          </p>
          <p>
          Additionally, we provide livelihood support at places of origin, mitigating displacement risks and fostering community resilience. Our tailored interventions align with local contexts and community leaders, promoting sustainable economic growth and reducing vulnerability to climate change.
          </p>

            <h3 className="section-title">
            Integration
            </h3>
          
    <p>
    CDA strives to help IDPs and returnees live peacefully with their host community members through sports and cultural initiatives, which is important for bringing a sense of normalcy and dealing with trauma especially (so important for the ones who fled conflict and natural disasters).
    </p>
          

        
        </div>
      </secton>
   
    </div>
  );
}
