import React from "react";
import "./principles.scss";
import TopCard from "../../components/TopCard/TopCard";
import { Helmet } from "react-helmet";


const contents = [
{
  title:'We Build Trust',
  paragraph:'Honesty, transparency, and respect are necessary for collaborating with others while confidently exploring new horizons. As we establish partnerships with partners and the general public that promote shared objectives and common interests, we endeavor to always act with integrity. '
},
{
  title:'We Are Neutral',
  paragraph:'We do not engage in any racial, religious or political controversy. Nevertheless, we can denounce human rights violations we witness as well as obstacles put in the way of sufficiently expeditious and robust humanitarian activities. '
},

{
  title:'We Are Collaborative',
  paragraph:'Addressing the causes of climate change and its impact requires collaboration across disciplines and sectors. By reaching out and building partnerships across fields and encouraging public participation in the design and implementation of policies, we endeavor to contribute to a future that benefits us all, and none is left behind.  '
},

{
  title:'We Believe in Humanity',
  paragraph:'Climate-Displacement Action is determined to bring assistance without discrimination to climate-affected communities, and endeavors, to prevent and alleviate the impact of climate change on local individuals and households around the world. Its main purpose is to protect life and to ensure respect and protection for human beings. We promote and advocate for mutual understanding, inclusion, and gender equality. '
},
{
  title:'We Are Research Oriented',
  paragraph:`We definitely don't have all the answers, but we're always in search of them. We support and advocate for evidence-based decision-making processes. This is a calling that requires us to continually act with humility, excitement for new ideas, respect for others' knowledge, and faith in progress.`
},
{
  title:'We Are Impartial',
  paragraph:'We support affected communities without any discrimination as to nationality, race, religious beliefs, class, or political opinions. Our work is guided solely by the needs of individuals and communities. '
},
{
  title:'We Are Non-Profit',
  paragraph:'Climate-Displacement Organization is a not-for-profit movement whose objectives are not prompted in any manner by a desire for gain.'
},
{
  title:'We Believe in Transparency ',
  paragraph:'We take transparency and accountability very seriously. Climate-Displacement Action is committed to respecting a policy of transparency and disclosure for its beneficiaries, donors, and partners by making available all information on the allocation and management of its funds, and by providing an independent audit of its good management.'
}


]



export default function About() {
  return (
    <div className="container">
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="keywords"
          content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
        />
        <link rel="canonical" href="https://climate-displacementaction.org" />
        <meta name="author" content="Fayez Sahak" />
        <meta
          property="og:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://climate-displacementaction.org"
        />
        <meta
          property="og:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          property="og:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta
          name="twitter:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta
          name="twitter:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="twitter:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@climatedisplacementaction" />
      </Helmet>

      <TopCard
        cardTitle={"Principles"}
        cardImage={process.env.PUBLIC_URL + "/assets/images/svgs/contact.svg"}
      />

      <secton className="about-section">
     <h1 style={{color:'#00538a'}}>
      
Our Guiding Values & Principles 

     </h1>
     <p className="para" style={{borderBottom:'2px solid #00538a'}}>
     CLIMATE-DISPLACEMENT ACTION is guided by firm values that keep us grounded in a world that is always in a state of flux. These values bind us to a common goal, shape our work, direct our decisions, and manifest themselves in all that we do to further our mission.
     </p>



      {contents.map((item) => (
 <div>

 <p className="cards-header">
{item.title}
 </p>
 <p className="para">
 {item.paragraph}

 </p>
 
</div>
      ))}
    
      </secton>
   
    </div>
  );
}
