import React from "react";
import "./footer.scss";

import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";


export default function Footer() {
 

  return (
    <footer className="footer">
      <div className=" footer-div">
        <div className="col-lg-6">
          <ul>
            <li>
              <div className="logo">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "./assets/images/content/footer-log.png"
                  }
                  alt="LOGO"
                ></img>
              </div>
              <p style={{lineHeight:'24px'}}>
                Climate-Displacement Organization is a not-for-profit movement
                whose objectives are not prompted in any manner by a desire for
                gain.
              </p>
            </li>
            <h1>SOCIAL LINKS</h1>
            <BsFacebook className="bs" />
            <BsLinkedin className="bs" />
            <BsTwitter className="bs" />
          </ul>
        </div>
     
        <div className="col-lg-6">
          <ul>
            <li>
              <h1>CONTACT</h1>
              <li>CLIMATE-DISPLACEMENT ACTION-CANADA</li>
              <li>Taimani Watt, Street 6,</li>
              <li>House 27</li>
              <li>Kabul, Afghanistan</li>
              
              <li>
                Email:<a href="mailto:info@CDA.ca">contact@climate-displacementaction.org</a>
              </li>
           
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-nav">
        <div>
          <span>© 2023 CDA. All Rights Reserved</span>
        </div>
        <div>
          <a href="/">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}
