import React, { useEffect } from "react";
import "./topcard.scss";
import { init } from "aos";
export default function TopCard({ cardTitle, cardImage, alt }) {
  useEffect(() => {
    init();
  }, []);
  return (
    <div
      className="card-div"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="200"
      data-aos-offset="200"
    >
      <h3>{cardTitle}</h3>

      <img className="img-fluid" src={cardImage} alt={alt} />
    </div>
  );
}
