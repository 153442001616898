import React, { useState, useRef } from "react";
import "./contact.scss";
import TopCard from "../../components/TopCard/TopCard";
import Col from "react-bootstrap/Col";
import { Form, FormControl, Accordion,Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet";
import emailjs from '@emailjs/browser';
import Row from "react-bootstrap/Row";

import "aos/dist/aos.css";
const accordionItems = [
 
  {
    key: "1",
    header: <h3>AFGHANISTAN</h3>,
    listItems: [
      "CLIMATE-DISPLACEMENT ACTION-USA",
      "Taimani Wat, St 7. House # 21",
      "District 10, Kabul",
      "Email: contact@climate-displacementaction.org",
      "Phone: +93 (700) 27 5379",
    ],
  },

];

export default function Contact() {
  const [isOpen, setIsOpen] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleAccordionClick = (itemKey) => {
    if (isOpen === itemKey) {
      setIsOpen("");
    } else {
      setIsOpen(itemKey);
    }
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_4w09c4p', 'template_mz6rqev', form.current, 'HrWG-7Zs-owg6PZsZ')
      .then((result) => {
        setShowModal(true);
        form.current.reset(); // Reset the form
      }, (error) => {
        console.log(error.text);
      });
  };
  
  const closeModal = () => {
    setShowModal(false);
  };
  


  return (
    <div className="container contact">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="keywords"
          content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
        />
        <link rel="canonical" href="https://climate-displacementaction.org" />
        <meta name="author" content="Fayez Sahak" />
        <meta
          property="og:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://climate-displacementaction.org"
        />
        <meta
          property="og:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          property="og:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta
          name="twitter:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta
          name="twitter:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="twitter:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@climatedisplacementaction" />
      </Helmet>

      <TopCard
        cardTitle={"Contact Us"}
        cardImage={process.env.PUBLIC_URL + "/assets/images/svgs/contact.svg"}
        alt={"contact-illustration"}
      />

      <div className="form-svg">
        <Form className="contact-form" ref={form} onSubmit={sendEmail}>
          <Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom01"
              className="mb-4"
            >
              <Form.Control required type="text" placeholder="Your Name" name="user_name"/>
            </Form.Group>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom02"
              className="mb-4"
            >
              <Form.Control
                required
                type="text"
                name="user_email"
                placeholder="Youremail@example.com"
                
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              md="12"
              controlId="validationCustom03"
              className="mb-4"
            >
              <Form.Control
                type="text"
                placeholder="Subject of discussion"
                required
                name="subject"
              />
            </Form.Group>
          </Row>

          <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-4">
            <FormControl
              as="textarea"
              rows={4}
              cols={12}
              name='message'
              placeholder="Your message..."
              style={{ maxHeight: "150px" }}
            />
          </Form.Group>

          <button className="btn-primary" type={"submit"}>
            Submit Form
          </button>
        </Form>
        {showModal && (
        <div className="modal show" style={{ display: 'block', position: 'absolute' }}>
          <Modal.Dialog>
      

            <Modal.Body>
              <p style={{fontSize:'18px',color:'#00538a'}}>Thanks For Contacting Us!</p>
            </Modal.Body>

            <Modal.Footer>
            <Button variant="primary" onClick={closeModal}>Close</Button>

              
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}

        <section className=" contact-cards">
          <Accordion className="header-1">
            {accordionItems.map((item) => (
              <Accordion.Item
                eventKey={item.key}
                key={item.key}
                onClick={() => handleAccordionClick(item.key)}
              >
                <Accordion.Header>{item.header}</Accordion.Header>
                <Accordion.Body>
                  <ul className="contact-ul">
                    {item.listItems.map((listItem, index) => (
                      <li key={index}>{listItem}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </section>
      </div>
    </div>
  );
}
