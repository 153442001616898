import React from "react";
import "./home.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Principle from "../../components/Principles/Principle";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet";
export default function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderArray = [
    {
      title: "CLIMATE-DISPLACEMENT ACTION",
      sliderImage: process.env.PUBLIC_URL + "./assets/images/content/img-1.jpg",
      para:' is a non-profit organization that protects and serves climate-affected communities in Afghanistan. With over 15 years of experience in conflict zones, natural disasters, and response to climate-affected people, the members of CLIMATE-DISPLACEMENT ACTION are committed to combating the climate catastrophe and its underlying causes.'
    },
    {
      title: "CLIMATE-DISPLACEMENT ACTION",
      sliderImage: process.env.PUBLIC_URL + "./assets/images/content/img-5.jpg",
      para:' is a non-profit organization that protects and serves climate-affected communities in Afghanistan. With over 15 years of experience in conflict zones, natural disasters, and response to climate-affected people, the members of CLIMATE-DISPLACEMENT ACTION are committed to combating the climate catastrophe and its underlying causes.'    },
    {
      title: "CLIMATE-DISPLACEMENT ACTION",
      sliderImage: process.env.PUBLIC_URL + "./assets/images/content/img-2.jpg",
      para:' is a non-profit organization that protects and serves climate-affected communities in Afghanistan. With over 15 years of experience in conflict zones, natural disasters, and response to climate-affected people, the members of CLIMATE-DISPLACEMENT ACTION are committed to combating the climate catastrophe and its underlying causes.'    },
    {
      title: "CLIMATE-DISPLACEMENT ACTION",
      sliderImage:
        process.env.PUBLIC_URL + "./assets/images/content/img-4.jpg",
        para:' is a non-profit organization that protects and serves climate-affected communities in Afghanistan. With over 15 years of experience in conflict zones, natural disasters, and response to climate-affected people, the members of CLIMATE-DISPLACEMENT ACTION are committed to combating the climate catastrophe and its underlying causes.'    },
  ];
  return (
    <>
      <section className="hero">
        <Helmet>
          <title>Home</title>
          <meta
            name="description"
            content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
          />
          <meta
            name="keywords"
            content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
          />
          <link rel="canonical" href="https://climate-displacementaction.org" />
          <meta name="author" content="Fayez Sahak" />
          <meta
            property="og:title"
            content="Climate Displacement Action-Canada | Helping those affected by climate change"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://climate-displacementaction.org"
          />
          <meta
            property="og:description"
            content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
          />
          <meta
            property="og:image"
            content="https://climate-displacementaction.org/images/logo.png"
          />
          <meta
            name="twitter:title"
            content="Climate Displacement Action-Canada | Helping those affected by climate change"
          />
          <meta
            name="twitter:description"
            content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
          />
          <meta
            name="twitter:image"
            content="https://climate-displacementaction.org/images/logo.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@climatedisplacementaction" />
        </Helmet>

        <Slider {...settings}>
          {sliderArray.map((item) => (
            <div className="slider">
              <img src={item.sliderImage} alt="nothing found"></img>

              <div className="txt-div ">
                <h2>{item.title}</h2>
                <p>{item.para}</p>
              </div>
            </div>
          ))}
        </Slider>
      </section>

      <Principle />
      <div class="et_pb_section et_pb_section_1 et_section_regular">
        <div class="et_pb_row et_pb_row_1">
          <div class="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">
            <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light" style={{backgroundColor: '#c7f0ff'}}>
              <div class=" vision">
                <h2 className="section-title">Research & Advocacy</h2>
                <p className="para">
                  <span>
                    Scientific evidence exists to suggest that climate change is
                    impacting different communities in different ways, posing
                    serious protection risks to women and children in
                    particular. However, we lack details on issues faced by
                    communities as a result of climate change and its
                    combination with social challenges.
                  </span>
                </p>
                <p className="para">
                  <span>
                    At CDA, we travel around Afghanistan, listen and speak to those
                    affected by climate change. Through our in-country
                    monitoring and research activities, we first identify the
                    problem and understand how we best can generate change. We
                    share our findings with stakeholders at all levels and
                    engage the public and policymakers for meaningful action to
                    help those in need.
                  </span>
                  <span>&nbsp;</span>
                </p>
                <p className="para">
                  <span>
                    We also draw on our years of experience to inspire
                    policymakers and communities to take action. From
                    governments to local community leaders, we advocate for
                    policies that address the needs of communities, the lack of
                    which is already forcing people to even flee their homes and
                    put their lives at greater risk.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="video-section">
  
          <div className="txt">
            <h2 className="video-h2">What We Do</h2>
            <p className="para">
              CLIMATE-DISPLACEMENT ACTION protects and serves local communities
              impacted as a result of climate change, including internally
              displaced persons, migrants, refugees, and host communities
              worldwide. We support climate-affected communities through
              livelihood, integration support, information-sharing, and
              adaptation to climate change.
            </p>
          </div>
          
       <div className="videos">
       <div className="video col-lg-6">
            <video
              src={process.env.PUBLIC_URL + "./assets/videos/video1.mp4"}
              controls
            ></video>
          </div>
          <div className="video col-lg-6">
            <video
              src={process.env.PUBLIC_URL + "./assets/videos/video2.mp4"}
              controls
            ></video>
          </div>
       </div>
       
          
      </section>
      <Parallax
        className="parallax"
        bgImage={process.env.PUBLIC_URL + "./assets/images/content/img-11.jpg"}
        strength={200}
      >
        <div className="parallax-text">
          <h3>
            <span>CLIMATE-DISPLACEMENT ACTION</span>
            <br />
            Seek funding to build sports infrastructure, or develop if there is
            any existing sports infrastructure to foster youth engagement in
            positive activities. This is particularly important because
            establishing safe spaces for community members, particularly women
            and girls will encourage them to speak and in the long run, increase
            understanding and reduce social tensions.
          </h3>
        </div>
      </Parallax>

    </>
  );
}
