import React from "react";
import "./policies.scss";
import TopCard from "../../components/TopCard/TopCard";


import { Helmet } from "react-helmet";

export default function Policies() {
  return (
    <div className="container">
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="keywords"
          content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
        />
        <link rel="canonical" href="https://climate-displacementaction.org" />
        <meta name="author" content="Fayez Sahak" />
        <meta
          property="og:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://climate-displacementaction.org"
        />
        <meta
          property="og:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          property="og:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta
          name="twitter:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta
          name="twitter:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="twitter:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@climatedisplacementaction" />
      </Helmet>

      <TopCard
        cardTitle={"Policies"}
        cardImage={process.env.PUBLIC_URL + "/assets/images/svgs/contact.svg"}
      />
        <div className="box">

      <h1>This Page is Under Construction!</h1>
        </div>
    </div>
  );
}
