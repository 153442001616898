import React from "react";

import Home from "./pages/Home/Home";
import Joinus from "./pages/Joinus/Joinus";
import Header from "./components/Header/Header";
import Whatwedo from "./pages/Whatwedo/Whatwedo";
import Footer from "./components/Footer/Footer";
import Whoweare from "./pages/Whoweare/Whoweare";
import Principles from './pages/Principles/Principles';
import Policies from './pages/Policies/Policies';
import Popup from "./components/PopUp/PopUp";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whatwedo" element={<Whatwedo />} />
          <Route path="/joinus" element={<Joinus />} />
          <Route path="/whoweare" element={<Whoweare />} />
          <Route path="/principles" element={<Principles/>} />
          <Route path="/policies" element={<Policies/>} />
       
        </Routes>
        <Popup />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
