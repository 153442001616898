import React from "react";
import "./principle.scss";
import Carousel from 'react-bootstrap/Carousel';


const cardData = [
  {
   
    alt: "no image found",
    title: "We Build Trust",
    para: "Honesty, transparency, and respect are necessary for collaborating with others while confidently exploring new horizons. As we establish partnerships with partners and the general public that promote shared objectives and common interests, we endeavor to always act with integrity. ",
  },
  {
   
    alt: "no image found",
    title: "We Are Neutral",
    para: " We do not engage in any racial, religious or political controversy. Nevertheless, we can denounce human rights violations we witness as well as obstacles put in the way of sufficiently expeditious and robust humanitarian activities. ",
  },
  {
   
    alt: "no image found",
    title: "We Are Collaborative",
    para: "Addressing the causes of climate change and its impact requires collaboration across disciplines and sectors. By reaching out and building partnerships across fields and encouraging public participation in the design and implementation of policies, we endeavor to contribute to a future that benefits us all, and none is left behind. ",
  },
 
  {
   
    alt: "no image found",
    title: "We Believe in Humanity",
    para: "Climate-Displacement Action is determined to bring assistance without discrimination to climate-affected communities, and endeavors, to prevent and alleviate the impact of climate change on local individuals and households around the world. Its main purpose is to protect life and to ensure respect and protection for human beings. We promote and advocate for mutual understanding, inclusion, and gender equality.  ",
  },
  {
   
    alt: "no image found",
    title: "We Are Research Oriented",
    para: " We definitely don't have all the answers, but we're always in search of them. We support and advocate for evidence-based decision-making processes. This is a calling that requires us to continually act with humility, excitement for new ideas, respect for others' knowledge, and faith in progress. ",
  },
  {
   
    alt: "no image found",
    title: "We Are Impartial",
    para: " We support affected communities without any discrimination as to nationality, race, religious beliefs, class, or political opinions. Our work is guided solely by the needs of individuals and communities.  ",
  },
  {
   
    alt: "no image found",
    title: "We Are Non-Profit",
    para: " Climate-Displacement Organization is a not-for-profit movement whose objectives are not prompted in any manner by a desire for gain. ",
  },
  {
   
    alt: "no image found",
    title: "We Believe in Transparency ",
    para: "We take transparency and accountability very seriously. Climate-Displacement Action is committed to respecting a policy of transparency and disclosure for its beneficiaries, donors, and partners by making available all information on the allocation and management of its funds, and by providing an independent audit of its good management. ",
  },
];
export default function Principle() {
  return (
    <section className="principle">
    <div className="cards-div">
      <h1 className="section-title" style={{ textAlign: 'left' }}>
        Our Guiding <span>Values & Principles</span>
      </h1>
      <h2 className="para" style={{paddingBottom:'16px, ', textAlign:'justify'}}>CLIMATE-DISPLACEMENT ACTION is guided by firm values that keep us grounded in a world that is always in a state of flux. These values bind us to a common goal, shape our work, direct our decisions, and manifest themselves in all that we do to further our mission.</h2>
      <Carousel  interval={3000} style={{padding:'16px 0'}} >
        {cardData.map((item, index) => (
          <Carousel.Item key={index}  className="carousel">
            <div className="team">
              <div style={{ overflow: 'hidden' }}>
                <svg
                  preserveAspectRatio="none"
                  viewBox="0 0 1200 120"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: '#5e9be3' }}
                >
                  <path d="M1200 120L0 16.48V0h1200v120z" />
                </svg>
              </div>
              <div style={{ padding: '16px' }}>
                <h3 className="card-title">{item.title}</h3>
                <p className="para">{item.para}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  </section>
  
  );
}
