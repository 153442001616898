import React from "react";
import "./team.scss";
import { Helmet } from "react-helmet";
import TopCard from "../../components/TopCard/TopCard";


export default function Team() {
  const infoArray = [

    {
      memberImg:
        process.env.PUBLIC_URL + "./assets/images/content/member-2.jpg",
      memberName: "Tabasum Noori",
      memberPosition: "Director of Operations",
      memberBio: "is a researcher in Kabul, focusing on the crucial cause of studying food insecurity and the resulting protection risks. With a diploma in English and a BSc in Law and Political Science, Tabasum is working for protection of vulnerable communities in rural areas of Afghanistan, striving to comprehend the multifaceted challenges posed by food insecurity in Afghanistan. Proficient in various local languages, she effectively engages with diverse communities. Outside of her work, Tabasum values personal growth, interacts socially, and hones her research skills. Her unwavering commitment to researching food insecurity and advocating for those impacted reflects her dedication to creating positive change."
    },
    {
      memberImg:
        process.env.PUBLIC_URL + "./assets/images/content/member-3.jpg",
      memberName: "Zeinab Rezae",
      memberPosition: "Community Engagement & Gender Specialist",
      memberBio: `is one of the running’s most iconic figures in South Asia. Not just for breaking barriers as the first girl from Afghanistan to officially participate in running races, but also for creating positive social change. Because of her many other girls have been inspired and empowered by participating in running contests.
      Zeinab completed three marathons and a 250-kilometer multi-stage ultramarathon in the Gobi Desert of Mongolia. She became the first Afghan girl to win an IRONMAN 70.3 triathlon race in Dubai in 2022. As her latest sport achievement, Zeinab succeeded to cross the finish line of the IRONMAN 70.3 World Championship in Saint George Utah attended by more than 2,000 participants from around the world. By participating in sports activities, Zeinab has learned more about how she can use her platform as a female sports leader to promote gender equality and women’s empowerment.   Already a prominent voice for gender equality, Zeinab regularly appears at social events. She is currently working on facilitating and organizing sports and cultural activities for girls to ensure integration, social cohesion, and peaceful co-existence
      `
    },
  
    {
      memberImg:
        process.env.PUBLIC_URL + "./assets/images/content/member-4.jpg",
      memberName: "Leena Yousufi ",
  
      memberBio:"is a passionate human rights advocate in Afghanistan, with over 15 years of experience collaborating with national and international non-governmental organizations and UN agencies. She's a dedicated law graduate who has focused on safeguarding the rights of women and children, particularly those displaced due to conflicts and natural disasters. Currently, Leena leads an education program aimed at empowering young girls by enhancing their skills and knowledge. Her tireless commitment to human rights, especially in the context of climate displacement, continues to inspire change and create a brighter future for the marginalized in Afghanistan."
    },
    {
      memberImg:
        process.env.PUBLIC_URL + "./assets/images/content/member-1.jpg",
      memberName: "Fayez Sahak",
      memberPosition: "Executive Officer",
      memberBio:"is an international research consultant. His research has focused on human rights, internal displacement, the environment, livelihood, food security, and accountability systems. Fayez led studies on gender-based violence, local governance (including planning and budgeting at the sub-national level), humanitarian response, and protection and rights of IDPs and assessed government institutions and procedures. Fayez started his career working on climate change, designing, and delivering advocacy campaigns and strategies for reducing vulnerability and building resilience to environmental and natural disasters in developing countries. His work also consisted of assessing legal frameworks and administrative functions -including national banks and determining vulnerabilities to corruption."
        },
  ];

  const positions = [
    {
      position:'Executive Officer',
      names:'Fayez Sahak'
    },
    {
      position:'Director of Operations',
      names:'Salema Al. Khoja'
    },
    {
      position:'Secretary',
      names:'Haseena Wardak'
    },
    {
      position:'Communications Specialist',
      names:'Najib Aseel'
    },
    {
      position:'Community Engagement & Gender Specialist',
      names:'Zeinab Rezae'
    },
  
    {
      position:'Administration Coordinator ',
      names:'Samira Sultani'
    }


  ]

 



  return (
    <div className="container team ">
      <Helmet>
        <title>Team</title>
        <meta
          name="description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="keywords"
          content="climate, displacement, action, Canada, non-profit, organization, helping, affected, climate change, CDA"
        />
        <link rel="canonical" href="https://climate-displacementaction.org" />
        <meta name="author" content="Fayez Sahak" />
        <meta
          property="og:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://climate-displacementaction.org"
        />
        <meta
          property="og:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          property="og:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta
          name="twitter:title"
          content="Climate Displacement Action-Canada | Helping those affected by climate change"
        />
        <meta
          name="twitter:description"
          content="Climate Displacement Action-Canada is a non-profit organization helping those affected by climate change. Our slogan is CDA, which stands for Climate, Displacement, or both together."
        />
        <meta
          name="twitter:image"
          content="https://climate-displacementaction.org/images/logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@climatedisplacementaction" />
      </Helmet>

      <TopCard
        cardTitle={"Who We Are"}
        cardImage={process.env.PUBLIC_URL + "/assets/images/svgs/team1.svg"}
        alt={"Team Illustration"}
      />

      <p className="para" style={{padding:'16px 0'}}>

       is a non-profit organization that protects and serves climate-affected communities in Afghanistan. With over 15 years of experience in conflict zones, natural disasters, and response to climate-affected people, the members of CLIMATE-DISPLACEMENT ACTION are committed to combating the climate catastrophe and its underlying causes.     </p>
      
      <h1 className="director-title">
        Board Of Directors
      </h1>
          <div className="allteam  col-lg-12">
      {infoArray.map((item) => (
        <div className="team-div">

        
          <div className="team-card col-lg-4">
            <img src={item.memberImg} alt="no found"></img>
          </div>
          <div className="member-info">
          <h2 className="">{item.memberName}</h2>
            <p className="team-para">{item.memberBio}</p>
          </div>
          </div>
      ))}


        </div>
        <h1 className="director-title">
          Staff
        </h1>
        <ul className="position-ul">
          {positions.map((item)=>(

<li className="para">
<span className="positions">{item.position}</span> <p>
{item.names}
</p>
</li>
          ))}
        </ul>
    </div>
  );
}
